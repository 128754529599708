<template lang="html">

  <section class="events">
    <hero-global img="/img/hero/actualidad.svg" title="Actualidad" subtitle="Actualidad" />
    <div class="container">
      <div class="column-center m-bottom-40-px">
        <h2 class="title-grand title__double-center" data-text="Agenda CERMI">Agenda</h2>
      </div>

      <section class="grid-3">
        <template v-if="store.eventsCalendar.items">
          <section class="grid calendar">
            <p class="title-dest-underline">Calendario</p>
            <calendar :events="store.eventsCalendar.items" @datesCalendar="getEventsCalendar"></calendar>
            <div class="justify-content-center d-flex w-100">
              <router-link :to="{name:'HemerotecaEvents'}" title="Ver eventos anteriores" alt="Ir a Ver eventos anteriores" class="btn-white">Ver eventos anteriores</router-link>
            </div>
          </section>
        </template>
        <template v-if="store.getEvents(this.year, this.page)">
          <template v-for="(event, index) in Object.values(store.getEvents(this.year, this.page))" :key="index">
            <eventViewSimple :dataContent="event" typeContent="event"></eventViewSimple>
          </template>
        </template>
        <template v-else>
          <p class="title">No hay eventos próximamente</p>
        </template>
      </section>

    </div>
  </section>
</template>
<script lang="js">
  import HeroGlobal from "@/components/Global/HeroGlobal";
  import eventViewSimple from "@/components/Card/EventViewSimple";
  import { contentStore } from '@/stores/contents';
  import moment from 'moment';
  import calendar from "@/components/Global/Calendar";
  export default {
    name: 'events',
    props: [],
    components: {
      eventViewSimple,
      calendar,
      HeroGlobal
    },
    setup() {
      const store = contentStore();
      store.loadEventsCalendar()
      return {
        store
      }
    },
    mounted() {
      this.store.loadEvents(
        {
          dateCurrent: new Date(),
          page: this.page,
      
        }
      )
    },
    data() {
      return {
        year: new Date().getFullYear(),
        page: 1
      }
    },
    methods: {
      getEventsCalendar(item) {
       this.store.loadEventsCalendar({
          filtersResults: {
            'starDate': item.min,
            'endDate': item.max
          }
        })
      },
      
    },
    computed: {

    }
  }


</script>

<style scoped lang="scss">
  .events {
    .grid.calendar {
      margin-right: 0;
      padding-right: 40px
    }

    /* .grid-3 .grid:nth-of-type(3n+3) {
      margin-right: 0 !important;
    } */
  }
</style>